<template>
  <div class="app s-flex-r-c-s">
    <router-view/>
  </div>
</template>

<style>
.app {
  width: 100vw;
}
@import "@/assets/css/initial.css";
@import "@/assets/css/common.css";
</style>
