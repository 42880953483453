import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    redirect: '/main/index'
  },
  {
    path: '/main',
    name: 'main',
    component: () => import('../views/main/main.vue'),
    children: [{
      path: '/main/index',
      name: 'index',
      component: () => import('../views/main/index/index.vue')
    },{
      path: '/main/myxz',
      name: 'app',
      component: () => import('../views/main/app/app.vue')
    },{
      path: '/main/gywm',
      name: 'aboutUs',
      component: () => import('../views/main/aboutUs/aboutUs.vue')
    }]
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
